// PowerControlUI.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Alert } from './components/ui/alert';
import LoginForm from './LoginForm';
import MachineCard from './MachineCard';
import Header from './Header';
import { fetchMachines, handlePowerAction, queryPowerState, isAuthenticated, logout } from './api';
import { startErrorTimer } from './utils';
import GalaxyLoader from './GalaxyLoader';
import { jwtDecode } from 'jwt-decode';
import logger from './logger.js';

const PowerControlUI = () => {
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pendingActions, setPendingActions] = useState({});
  const [errorTimers, setErrorTimers] = useState({});
  const [titleKey, setTitleKey] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [user, setUser] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const pollIntervals = useRef({});

  const isProduction = process.env.REACT_APP_ENV === 'production';

  useEffect(() => {
    if (isLoggedIn) {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          setUser(decoded);
        } catch (error) {
          handleLogout();
        }
      }
    }
  }, [isLoggedIn]);

  const handleError = useCallback((errorMessage) => {
    if (errorMessage.includes('ngrok') || errorMessage.includes('Tunnel') || errorMessage.includes('404 Not Found')) {
      console.error('Connection error:', errorMessage);
      if (!isProduction) {
        setError('Connection error. Please check your network and try again.');
      }
    } else {
      console.error(errorMessage);
      if (!isProduction) {
        setError(errorMessage);
      }
    }
  }, [isProduction]);

  const fetchMachinesCallback = useCallback(async (isSync = false) => {
    if (!isLoggedIn) return;
    setLoading(true);
    setError(null);
    try {
      const data = await fetchMachines('', isSync);
      setMachines(prevMachines => {
        const updatedMachines = Array.isArray(data) ? data
          .filter(machine => machine.status_name === "Deployed")
          .map(newMachine => {
            const prevMachine = prevMachines.find(m => m.system_id === newMachine.system_id);
            if (prevMachine && prevMachine.power_state === 'pending') {
              return { ...newMachine, power_state: 'pending' };
            }
            if (newMachine.power_state === 'error' && !errorTimers[newMachine.system_id]) {
              startErrorTimer(newMachine.system_id, setMachines, setErrorTimers);
            }
            return newMachine;
          }) : [];

        return updatedMachines.sort((a, b) => a.hostname.localeCompare(b.hostname));
      });
      setTitleKey(prevKey => prevKey + 1);
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        handleLogout();
      } else {
        handleError(`Failed to fetch machines. ${error.message}`);
      }
    } finally {
      setLoading(false);
      setOverlayVisible(true);
    }
  }, [isLoggedIn, errorTimers, handleError]);

  const checkPowerStatus = useCallback(async (systemId, action) => {
    const expectedStatus = action === 'power_on' ? 'on' : 'off';
    
    try {
      const powerState = await queryPowerState(systemId);
      
      if (powerState.state === expectedStatus) {
        setPendingActions(prev => {
          const newPending = { ...prev };
          delete newPending[systemId];
          return newPending;
        });
        
        setMachines(prev => {
          const updatedMachines = prev.map(machine =>
            machine.system_id === systemId ? { ...machine, power_state: expectedStatus } : machine
          );
          return updatedMachines.sort((a, b) => a.hostname.localeCompare(b.hostname));
        });
      } else {
        setPendingActions(prev => {
          const newPending = { ...prev };
          delete newPending[systemId];
          return newPending;
        });
        
        setMachines(prev => prev.map(machine =>
          machine.system_id === systemId
            ? { ...machine, power_state: 'error' }
            : machine
        ));
        
        handleError(`Power ${action} operation failed`);
        startErrorTimer(systemId, setMachines, setErrorTimers);
      }
    } catch (error) {
      setPendingActions(prev => {
        const newPending = { ...prev };
        delete newPending[systemId];
        return newPending;
      });
      
      setMachines(prev => prev.map(machine =>
        machine.system_id === systemId
          ? { ...machine, power_state: 'error' }
          : machine
      ));
      
      handleError(`Error checking power status: ${error.message}`);
      startErrorTimer(systemId, setMachines, setErrorTimers);
    }
  }, [handleError]);

  const handlePowerActionCallback = useCallback(async (systemId, action) => {
    setError(null);
    setPendingActions(prev => ({ ...prev, [systemId]: action }));
    setMachines(prev => prev.map(machine =>
      machine.system_id === systemId
        ? { ...machine, power_state: 'pending' }
        : machine
    ));
    
    try {
      // Send single power command and wait for response
      const response = await handlePowerAction(systemId, action);
      logger.debug(`${action} action completed for machine ${systemId}`);
      
      // Update machine state based on response
      setPendingActions(prev => {
        const newPending = { ...prev };
        delete newPending[systemId];
        return newPending;
      });
      
      setMachines(prev => {
        const updatedMachines = prev.map(machine =>
          machine.system_id === systemId 
            ? { ...machine, power_state: action === 'power_on' ? 'on' : 'off' }
            : machine
        );
        return updatedMachines.sort((a, b) => a.hostname.localeCompare(b.hostname));
      });
  
    } catch (error) {
      logger.error(`Failed to ${action} machine`, { error: error.message });
      setPendingActions(prev => {
        const newPending = { ...prev };
        delete newPending[systemId];
        return newPending;
      });
      setMachines(prev => prev.map(machine =>
        machine.system_id === systemId
          ? { ...machine, power_state: 'error' }
          : machine
      ));
      startErrorTimer(systemId, setMachines, setErrorTimers);
    }
  }, [logger, setError, setMachines, setErrorTimers]);

  const handleLogin = async () => {
    setIsLoggedIn(true);
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = jwtDecode(token);
      setUser(decoded);
    }
    await fetchMachinesCallback();
  };

  const handleLogout = () => {
    Object.values(pollIntervals.current).forEach(clearInterval);
    pollIntervals.current = {};
    Object.values(errorTimers).forEach(clearTimeout);
    
    logout();
    setUser(null);
    setIsLoggedIn(false);
    setMachines([]);
    setOverlayVisible(false);
    window.location.href = '/';
  };

  useEffect(() => {
    let machinesPollInterval;
    
    if (isLoggedIn) {
      console.log('Starting machine polling');
      // Initial fetch
      fetchMachinesCallback();
      
      // Poll every 60 seconds
      machinesPollInterval = setInterval(() => {
        console.log('Polling machines (60s interval)');
        fetchMachinesCallback();
      }, 60000);
      
      return () => {
        console.log('Cleaning up polling interval');
        if (machinesPollInterval) clearInterval(machinesPollInterval);
        Object.values(pollIntervals.current).forEach(clearInterval);
        Object.values(errorTimers).forEach(clearTimeout);
      };
    }
  }, [isLoggedIn, fetchMachinesCallback]);

  if (!isLoggedIn) {
    return (
      <LoginForm
        onLogin={handleLogin}
        error={error}
        setError={setError}
      />
    );
  }

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <GalaxyLoader />
      <div 
        className={`absolute inset-0 bg-black transition-opacity duration-3000 ease-in-out ${overlayVisible ? 'opacity-60' : 'opacity-0'}`}
      ></div>
      <div className="absolute inset-0 overflow-auto">
        <div className="p-6 min-h-screen">
          <div className="max-w-7xl mx-auto">
            <Header
              titleKey={titleKey}
              handleLogout={handleLogout}
              fetchMachines={() => fetchMachinesCallback(true)}
              isAdmin={user?.role === 'admin'}
            />
            {!isProduction && error && (
              <Alert variant="destructive" className="mb-6 bg-red-900 border border-red-700 text-red-100">
                <AlertTriangle className="inline-block mr-2 h-5 w-5" />
                <span>{error}</span>
              </Alert>
            )}
            {loading && machines.length === 0 ? (
              <div className="text-center mt-10 text-white">
                <p></p>
              </div>
            ) : machines.length === 0 ? (
              <div className="text-center mt-10 text-white">
                <p>No machines available.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                {machines.map(machine => (
                  <MachineCard
                    key={machine.system_id}
                    machine={machine}
                    handlePowerAction={handlePowerActionCallback}
                    pendingActions={pendingActions}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerControlUI;
