// components/LogViewer.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { 
  RotateCcw, 
  Download,
  Search,
  AlertTriangle,
  Info,
  Bug,
  AlertCircle
} from 'lucide-react';
import logger from './logger';

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('all');
  const [autoRefresh, setAutoRefresh] = useState(true);
  const logContainerRef = useRef(null);

  const refreshLogs = () => {
    setLogs(logger.getLogs());
  };

  useEffect(() => {
    refreshLogs();
    let interval;
    
    if (autoRefresh) {
      interval = setInterval(refreshLogs, 1000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [autoRefresh]);

  const filteredLogs = logs.filter(log => {
    const matchesFilter = 
      log.message.toLowerCase().includes(filter.toLowerCase()) ||
      (log.data && JSON.stringify(log.data).toLowerCase().includes(filter.toLowerCase()));
    
    const matchesLevel = selectedLevel === 'all' || log.level === selectedLevel;
    
    return matchesFilter && matchesLevel;
  });

  const handleExport = () => {
    const exportData = {
      exportedAt: new Date().toISOString(),
      logs: filteredLogs
    };
    
    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `system-logs-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const LogLevelIcon = ({ level }) => {
    switch (level) {
      case 'error':
        return <AlertTriangle className="h-4 w-4 text-red-400" />;
      case 'warn':
        return <AlertCircle className="h-4 w-4 text-yellow-400" />;
      case 'debug':
        return <Bug className="h-4 w-4 text-purple-400" />;
      default:
        return <Info className="h-4 w-4 text-blue-400" />;
    }
  };

  const scrollToBottom = () => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-white">System Logs</h2>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={refreshLogs}
            className="bg-gray-700 hover:bg-gray-600"
          >
            <RotateCcw className="h-4 w-4 mr-2" />
            Refresh
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleExport}
            className="bg-gray-700 hover:bg-gray-600"
          >
            <Download className="h-4 w-4 mr-2" />
            Export
          </Button>
        </div>
      </div>

      <div className="flex space-x-4 mb-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Filter logs..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="pl-10 bg-gray-700 border-gray-600"
            />
          </div>
        </div>
        <select
          value={selectedLevel}
          onChange={(e) => setSelectedLevel(e.target.value)}
          className="bg-gray-700 border border-gray-600 rounded-md px-3 py-2 text-white"
        >
          <option value="all">All Levels</option>
          <option value="info">Info</option>
          <option value="warn">Warnings</option>
          <option value="error">Errors</option>
          <option value="debug">Debug</option>
        </select>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setAutoRefresh(!autoRefresh)}
          className={`${
            autoRefresh ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-700 hover:bg-gray-600'
          }`}
        >
          {autoRefresh ? 'Auto-refresh On' : 'Auto-refresh Off'}
        </Button>
      </div>

      <div 
        ref={logContainerRef}
        className="bg-gray-900 rounded-lg border border-gray-700 h-[500px] overflow-y-auto"
      >
        <div className="p-4 space-y-2">
          {filteredLogs.map((log, index) => (
            <div
              key={index}
              className={`p-2 rounded ${
                log.level === 'error' ? 'bg-red-900/20' :
                log.level === 'warn' ? 'bg-yellow-900/20' :
                log.level === 'debug' ? 'bg-purple-900/20' :
                'bg-blue-900/20'
              }`}
            >
              <div className="flex items-start">
                <LogLevelIcon level={log.level} />
                <div className="ml-2 flex-1">
                  <div className="flex justify-between items-start">
                    <span className="text-xs text-gray-400">
                      {new Date(log.timestamp).toLocaleString()}
                    </span>
                    <span className={`text-xs px-2 py-0.5 rounded ${
                      log.level === 'error' ? 'bg-red-900 text-red-200' :
                      log.level === 'warn' ? 'bg-yellow-900 text-yellow-200' :
                      log.level === 'debug' ? 'bg-purple-900 text-purple-200' :
                      'bg-blue-900 text-blue-200'
                    }`}>
                      {log.level.toUpperCase()}
                    </span>
                  </div>
                  <div className="text-sm text-white mt-1">{log.message}</div>
                  {log.data && (
                    <pre className="mt-1 text-xs text-gray-400 overflow-x-auto">
                      {typeof log.data === 'object' 
                        ? JSON.stringify(log.data, null, 2)
                        : log.data}
                    </pre>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {filteredLogs.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          No logs found matching the current filters
        </div>
      )}
    </div>
  );
};

export default LogViewer;
