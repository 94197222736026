// api.js
const API_BASE_URL = '/api';

const makeRequest = async (url, options = {}, isSync = false) => {
  const token = localStorage.getItem('authToken');
  
  const headers = {
    ...options.headers,
    'Authorization': token ? `Bearer ${token}` : '',
    'X-Sync-Request': isSync ? 'true' : 'false',
    'ngrok-skip-browser-warning': '1'
  };
  
  if (process.env.NODE_ENV === 'development') {
    console.log('Making request to:', url);
  }
  
  try {
    const response = await fetch(url, { ...options, headers });
  
    if (!response.ok) {
      const errorText = await response.text();
      if (response.status === 401 || response.status === 403) {
        // Token is invalid or expired, clear it and redirect to login
        localStorage.removeItem('authToken');
        window.location.href = '/';
        throw new Error('Session expired. Please log in again.');
      }
      if (process.env.NODE_ENV === 'development') {
        console.error('API Error:', errorText);
      }
      throw new Error(`Request failed: ${response.status} ${response.statusText}. ${errorText}`);
    }
  
    const data = await response.json();
    if (process.env.NODE_ENV === 'development') {
      console.log('API Response:', sanitizeLogData(data));
    }
    return data;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Request error:', error);
    }
    throw error;
  }
};

// Helper function to sanitize log data
const sanitizeLogData = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => ({
      system_id: item.system_id,
      hostname: item.hostname,
      status_name: item.status_name,
      power_state: item.power_state
    }));
  } else if (typeof data === 'object' && data !== null) {
    return {
      system_id: data.system_id,
      hostname: data.hostname,
      status_name: data.status_name,
      power_state: data.power_state
    };
  }
  return data;
};

// Auth functions
export const login = async (username, password) => {
  const response = await fetch('/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Login failed');
  }

  const data = await response.json();
  localStorage.setItem('authToken', data.token);
  return data;
};

export const logout = () => {
  localStorage.removeItem('authToken');
  window.location.href = '/';
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

// Machine API functions
export const fetchMachines = async (systemId = '', isSync = false) => {
  const url = systemId ? `${API_BASE_URL}/machines/${systemId}/` : `${API_BASE_URL}/machines/`;
  return makeRequest(url, { method: 'GET' }, isSync);
};

export const handlePowerAction = async (systemId, action) => {
  let endpoint;
  switch (action) {
    case 'power_on':
      endpoint = 'op-power_on';
      break;
    case 'power_off':
      endpoint = 'op-power_off';
      break;
    default:
      throw new Error(`Unsupported power action: ${action}`);
  }

  const url = `${API_BASE_URL}/machines/${systemId}/${endpoint}`;
  return makeRequest(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: '',
  });
};

export const queryPowerState = async (systemId) => {
  const url = `${API_BASE_URL}/machines/${systemId}/op-query_power_state`;
  return makeRequest(url, { method: 'GET' });
};
