import React, { useState } from 'react';
import { Key, AlertTriangle } from 'lucide-react';
import { Input } from './components/ui/input';
import { Alert } from './components/ui/alert';
import GalaxyLoader from './GalaxyLoader';

const LoginForm = ({ onLogin, error, setError }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      const response = await fetch('/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Login failed');
      }

      localStorage.setItem('authToken', data.token);
      onLogin(data.token);
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <GalaxyLoader />
      <div className="absolute inset-0 z-10 bg-black bg-opacity-10 backdrop-blur-[0px] flex items-center justify-center">
        <div className="max-w-sm w-full p-4 bg-gray-900 bg-opacity-80 rounded-lg shadow-xl">
          <h1 className="text-2xl font-bold mb-4 tracking-wide title-animate text-center text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
            Compute Control
          </h1>
          <div className="space-y-3">
            <div className="flex items-center justify-center">
              <Key className="h-5 w-5 mr-2 text-gray-400 icon-animate drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]" />
              <span className="text-lg font-semibold text-gray-300 drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">Internal Access</span>
            </div>
            <form onSubmit={handleSubmit} className="space-y-3">
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                className="bg-gray-800 bg-opacity-50 text-white border-gray-700 placeholder-gray-500 focus:border-gray-500 focus:ring-gray-500 text-sm"
                required
              />
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="bg-gray-800 bg-opacity-50 text-white border-gray-700 placeholder-gray-500 focus:border-gray-500 focus:ring-gray-500 text-sm"
                required
              />
              <button
                type="submit"
                disabled={isLoading}
                className="relative w-full bg-gray-900 text-white border border-gray-700 py-1.5 px-3 rounded-md overflow-hidden group text-sm"
              >
                <span className="relative z-10">
                  {isLoading ? 'Logging in...' : 'Login'}
                </span>
                <div className="absolute inset-0 overflow-hidden">
                  <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-700 to-gray-900 opacity-50 group-hover:opacity-100 animate-glow"></div>
                </div>
              </button>
            </form>
            {error && (
              <Alert variant="destructive" className="bg-red-900 bg-opacity-50 border-red-700 text-red-200 text-sm">
                <AlertTriangle className="inline-block mr-2 h-4 w-4" />
                <span>{error}</span>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
