// logger.js
const LOG_LEVELS = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  DEBUG: 'debug'
};

class Logger {
  constructor() {
    this.logs = [];
    this.maxLogs = 10000; // Keep last 10000 logs
  }

  formatTimestamp() {
    return new Date().toISOString();
  }

  formatMessage(level, message, data) {
    const timestamp = this.formatTimestamp();
    const logEntry = {
      timestamp,
      level,
      message,
      data: data || null
    };
    
    this.logs.unshift(logEntry); // Add to beginning of array
    
    if (this.logs.length > this.maxLogs) {
      this.logs = this.logs.slice(0, this.maxLogs);
    }
    
    // Also log to console with timestamp
    console[level](`[${timestamp}] ${message}`, data || '');
    
    return logEntry;
  }

  info(message, data) {
    return this.formatMessage(LOG_LEVELS.INFO, message, data);
  }

  warn(message, data) {
    return this.formatMessage(LOG_LEVELS.WARN, message, data);
  }

  error(message, data) {
    return this.formatMessage(LOG_LEVELS.ERROR, message, data);
  }

  debug(message, data) {
    return this.formatMessage(LOG_LEVELS.DEBUG, message, data);
  }

  getLogs() {
    return this.logs;
  }

  clear() {
    this.logs = [];
  }
}

const logger = new Logger();
module.exports = logger;
module.exports.LOG_LEVELS = LOG_LEVELS;