// Header.jsx
import React from 'react';
import { LogOut, RefreshCcw, Settings, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const IconButton = ({ onClick, icon: Icon, color, className }) => (
  <button 
    onClick={onClick} 
    className="relative w-10 h-10 rounded-full overflow-hidden hover:opacity-80 transition-opacity duration-200"
  >
    <div className="absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
    <div className="relative z-10 flex items-center justify-center w-full h-full">
      <Icon className={`h-5 w-5 ${color} ${className}`} />
    </div>
  </button>
);

const Header = ({ titleKey, handleLogout, fetchMachines, isAdmin }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');
  const user = token ? jwtDecode(token) : null;

  return (
    <>
      <style>{`
        @keyframes spin-reverse {
          from { transform: rotate(0deg); }
          to { transform: rotate(-360deg); }
        }
        .animate-spin-reverse {
          animation: spin-reverse 10s linear infinite;
        }
      `}</style>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <img 
            src="/logo.svg" 
            alt="Danucore" 
            className="h-10 w-auto mr-2"
          />
        </div>
        <div className="flex items-center space-x-4">
          {/* Username display */}
          <div className="flex items-center mr-2 bg-gray-800 px-3 py-1 rounded-full">
            <User className="h-4 w-4 text-gray-400 mr-2" />
            <span className="text-gray-200 text-sm">{user?.username}</span>
          </div>
          
          <div className="flex space-x-2">
            {isAdmin && (
              <IconButton 
                onClick={() => navigate('/admin')}
                icon={Settings} 
                color="text-blue-400" 
              />
            )}
            <IconButton 
              onClick={handleLogout} 
              icon={LogOut} 
              color="text-red-400" 
            />
            <IconButton 
              onClick={fetchMachines} 
              icon={RefreshCcw} 
              color="text-purple-400" 
              className="animate-spin-reverse"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;