import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { 
  Home, 
  AlertTriangle, 
  Loader2, 
  Plus, 
  Trash, 
  Edit, 
  Key, 
  Shield,
  Database,
  Check
} from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from './components/ui/alert-dialog';
import LogViewer from './LogViewer';
import logger from './logger';

// Memoized Modal Component
const Modal = memo(({ children, onClose, title }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4 border border-gray-700 shadow-xl">
      <div className="relative text-white">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-white">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  </div>
));

Modal.displayName = 'Modal';

// Memoized Form Components
const AddUserForm = memo(({ onSubmit, newUser, setNewUser }) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Username</label>
      <Input
        value={newUser.username}
        onChange={e => setNewUser(prev => ({ ...prev, username: e.target.value }))}
        className="bg-gray-700 border-gray-600"
        required
      />
    </div>
    <div>
      <label className="block text-sm font-medium mb-1">Password</label>
      <Input
        type="password"
        value={newUser.password}
        onChange={e => setNewUser(prev => ({ ...prev, password: e.target.value }))}
        className="bg-gray-700 border-gray-600"
        required
      />
    </div>
    <div>
      <label className="block text-sm font-medium mb-1">Role</label>
      <select
        value={newUser.role}
        onChange={e => setNewUser(prev => ({ ...prev, role: e.target.value }))}
        className="w-full bg-gray-700 border border-gray-600 rounded-md p-2 text-white"
        required
      >
        <option value="user">User</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <div>
      <label className="block text-sm font-medium mb-1">Resource Pools</label>
      <Input
        value={newUser.pools.join(',')}
        onChange={e => setNewUser(prev => ({
          ...prev,
          pools: e.target.value.split(',').map(p => p.trim()).filter(Boolean)
        }))}
        placeholder="Enter pool names (comma-separated)"
        className="bg-gray-700 border-gray-600"
      />
      <p className="text-xs text-gray-400 mt-1">
        Enter pool names separated by commas. New pools will be created automatically.
      </p>
    </div>
    <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700">
      Add User
    </Button>
  </form>
));

AddUserForm.displayName = 'AddUserForm';

const EditUserForm = memo(({ onSubmit, selectedUser, setSelectedUser }) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Role</label>
      <select
        value={selectedUser.role}
        onChange={e => setSelectedUser(prev => ({ ...prev, role: e.target.value }))}
        className="w-full bg-gray-700 border border-gray-600 rounded-md p-2 text-white"
      >
        <option value="user">User</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <div>
      <label className="block text-sm font-medium mb-1">Resource Pools</label>
      <Input
        value={selectedUser.pools || ''}
        onChange={e => setSelectedUser(prev => ({ ...prev, pools: e.target.value }))}
        placeholder="Enter pool names (comma-separated)"
        className="bg-gray-700 border-gray-600"
      />
      <p className="text-xs text-gray-400 mt-1">
        Enter pool names separated by commas. New pools will be created automatically.
      </p>
    </div>
    <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700">
      Update User
    </Button>
  </form>
));

EditUserForm.displayName = 'EditUserForm';

const ChangePasswordForm = memo(({ onSubmit, newPassword, setNewPassword }) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">New Password</label>
      <Input
        type="password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        className="bg-gray-700 border-gray-600"
        required
      />
    </div>
    <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700">
      Update Password
    </Button>
  </form>
));

ChangePasswordForm.displayName = 'ChangePasswordForm';

const AddPoolForm = memo(({ onSubmit, newPool, setNewPool }) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">Pool Name</label>
      <Input
        value={newPool.name}
        onChange={e => setNewPool(prev => ({ ...prev, name: e.target.value }))}
        className="bg-gray-700 border-gray-600"
        required
      />
    </div>
    <div>
      <label className="block text-sm font-medium mb-1">Description (optional)</label>
      <Input
        value={newPool.description}
        onChange={e => setNewPool(prev => ({ ...prev, description: e.target.value }))}
        className="bg-gray-700 border-gray-600"
      />
    </div>
    <Button type="submit" className="w-full bg-purple-600 hover:bg-purple-700">
      Add Pool
    </Button>
  </form>
));

AddPoolForm.displayName = 'AddPoolForm';

const UpdateApiKeyForm = memo(({ onSubmit, maasKey, setMaasKey, isKeyConfigured, keyLastUpdated }) => (
  <form onSubmit={onSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium mb-1">API Key</label>
      <Input
        type="password"
        value={maasKey}
        onChange={e => setMaasKey(e.target.value)}
        placeholder="consumer_key:token_key:token_secret"
        className="bg-gray-700 border-gray-600 font-mono"
        required
      />
      <p className="text-xs text-gray-400 mt-1">
        Format: consumer_key:token_key:token_secret
      </p>
      {keyLastUpdated && (
        <p className="text-xs text-gray-400 mt-1">
          Last updated: {new Date(keyLastUpdated).toLocaleString()}
        </p>
      )}
    </div>
    <Button type="submit" className="w-full bg-yellow-600 hover:bg-yellow-700">
      {isKeyConfigured ? 'Update API Key' : 'Configure API Key'}
    </Button>
  </form>
));

UpdateApiKeyForm.displayName = 'UpdateApiKeyForm';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [pools, setPools] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [success, setSuccess] = useState(null);

  // Form states
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role: 'user',
    pools: []
  });
  
  const [newPool, setNewPool] = useState({
    name: '',
    description: ''
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');

  // MaaS API key states
  const [maasKey, setMaasKey] = useState('');
  const [isKeyConfigured, setIsKeyConfigured] = useState(false);
  const [keyLastUpdated, setKeyLastUpdated] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchPools();
    checkApiKey();
  }, []);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const checkApiKey = async () => {
    try {
      logger.debug('Checking MaaS API key status');
      const response = await fetch('/api/admin/maas-key', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      const data = await response.json();
      setIsKeyConfigured(data.isConfigured);
      setKeyLastUpdated(data.lastUpdated);
    } catch (error) {
      logger.error('Failed to check API key status', { error: error.message });
    }
  };

  const fetchUsers = async () => {
    try {
      logger.debug('Fetching users');
      const response = await fetch('/api/admin/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to fetch users');
      }
      const data = await response.json();
      logger.debug('Users fetched successfully', { count: data.length });
      setUsers(data);
    } catch (error) {
      logger.error('Failed to fetch users', { error: error.message });
      setError(error.message);
    }
  };

  const fetchPools = async () => {
    try {
      logger.debug('Fetching resource pools');
      const response = await fetch('/api/admin/pools', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to fetch pools');
      }
      const data = await response.json();
      logger.debug('Resource pools fetched successfully', { count: data.length });
      setPools(data);
    } catch (error) {
      logger.error('Failed to fetch pools', { error: error.message });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      logger.info('Admin action: Creating new user', { username: newUser.username });
      const response = await fetch('/api/admin/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(newUser)
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create user');
      }

      logger.info('Admin action: User created successfully', { 
        username: newUser.username,
        role: newUser.role,
        pools: newUser.pools 
      });
      setShowModal(null);
      setNewUser({ username: '', password: '', role: 'user', pools: [] });
      setSuccess('User created successfully');
      fetchUsers();
      fetchPools();
    } catch (error) {
      logger.error('Failed to create user', { error: error.message });
      setError(error.message);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      logger.info('Admin action: Updating user permissions', { 
        username: selectedUser.username,
        role: selectedUser.role,
        pools: selectedUser.pools 
      });
      const response = await fetch(`/api/admin/users/${selectedUser.username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          role: selectedUser.role,
          pools: selectedUser.pools ? 
            (Array.isArray(selectedUser.pools) ? selectedUser.pools : selectedUser.pools.split(','))
            : []
        })
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to update user');
      }

      logger.info('Admin action: User permissions updated successfully', { 
        username: selectedUser.username 
      });
      setShowModal(null);
      setSelectedUser(null);
      setSuccess('User updated successfully');
      fetchUsers();
      fetchPools();
    } catch (error) {
      logger.error('Failed to update user', { error: error.message });
      setError(error.message);
    }
  };

  const handleAddPool = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      logger.info('Admin action: Creating new resource pool', { name: newPool.name });
      const response = await fetch('/api/admin/pools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(newPool)
      });
    
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create pool');
      }
    
      logger.info('Admin action: Resource pool created successfully', { 
        name: newPool.name,
        description: newPool.description 
      });
      setShowModal(null);
      setNewPool({ name: '', description: '' });
      setSuccess('Pool created successfully');
      fetchPools();
    } catch (error) {
      logger.error('Failed to create pool', { error: error.message });
      setError(error.message);
    }
  };

  const handleDeleteUser = async (username) => {
    try {
      logger.info('Admin action: Deleting user', { username });
      const response = await fetch(`/api/admin/users/${username}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to delete user');
      }

      logger.info('Admin action: User deleted successfully', { username });
      setSuccess('User deleted successfully');
      fetchUsers();
    } catch (error) {
      logger.error('Failed to delete user', { error: error.message });
      setError(error.message);
    }
  };

  const handleDeletePool = async (poolName) => {
    try {
      logger.info('Admin action: Deleting resource pool', { name: poolName });
      const response = await fetch(`/api/admin/pools/${poolName}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to delete pool');
      }

      logger.info('Admin action: Resource pool deleted successfully', { name: poolName });
      setSuccess('Pool deleted successfully');
      fetchPools();
    } catch (error) {
      logger.error('Failed to delete pool', { error: error.message });
      setError(error.message);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      logger.info('Admin action: Changing user password', { username: selectedUser.username });
      const response = await fetch(`/api/admin/users/${selectedUser.username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ password: newPassword })
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to change password');
      }

      logger.info('Admin action: User password changed successfully', { 
        username: selectedUser.username 
      });
      setShowModal(null);
      setSelectedUser(null);
      setNewPassword('');
      setSuccess('Password changed successfully');
    } catch (error) {
      logger.error('Failed to change password', { error: error.message });
      setError(error.message);
    }
  };

  const handleUpdateApiKey = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      logger.info('Admin action: Updating MaaS API key');
      const response = await fetch('/api/admin/maas-key', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ key: maasKey })
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to update API key');
      }

      logger.info('Admin action: MaaS API key updated successfully');
      setIsKeyConfigured(true);
      setKeyLastUpdated(new Date().toISOString());
      setShowModal(null);
      setMaasKey('');
      setSuccess('API key updated successfully');
    } catch (error) {
      logger.error('Failed to update API key', { error: error.message });
      setError(error.message);
    }
  };

  const renderModals = () => {
    switch (showModal) {
      case 'addUser':
        return (
          <Modal title="Add New User" onClose={() => setShowModal(null)}>
            <AddUserForm
              onSubmit={handleAddUser}
              newUser={newUser}
              setNewUser={setNewUser}
            />
          </Modal>
        );
      case 'editUser':
        return selectedUser && (
          <Modal 
            title={`Edit User: ${selectedUser.username}`} 
            onClose={() => { setShowModal(null); setSelectedUser(null); }}
          >
            <EditUserForm
              onSubmit={handleUpdateUser}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Modal>
        );
      case 'changePassword':
        return selectedUser && (
          <Modal 
            title={`Change Password: ${selectedUser.username}`} 
            onClose={() => { setShowModal(null); setSelectedUser(null); setNewPassword(''); }}
          >
            <ChangePasswordForm
              onSubmit={handleChangePassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
            />
          </Modal>
        );
      case 'addPool':
        return (
          <Modal 
            title="Add New Pool" 
            onClose={() => { setShowModal(null); setNewPool({ name: '', description: '' }); }}
          >
            <AddPoolForm
              onSubmit={handleAddPool}
              newPool={newPool}
              setNewPool={setNewPool}
            />
          </Modal>
        );
      case 'updateApiKey':
        return (
          <Modal 
            title={isKeyConfigured ? "Update MaaS API Key" : "Configure MaaS API Key"} 
            onClose={() => { setShowModal(null); setMaasKey(''); }}
          >
            <UpdateApiKeyForm
              onSubmit={handleUpdateApiKey}
              maasKey={maasKey}
              setMaasKey={setMaasKey}
              isKeyConfigured={isKeyConfigured}
              keyLastUpdated={keyLastUpdated}
            />
          </Modal>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold tracking-wide">Admin Panel</h1>
          <button
            onClick={() => navigate('/')}
            className="flex items-center px-4 py-2 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-300"
          >
            <Home className="mr-2 h-5 w-5" />
            Back to Dashboard
          </button>
        </div>

        {!isKeyConfigured && (
          <Alert variant="warning" className="mb-6 bg-yellow-900/50 border-yellow-700">
            <AlertTriangle className="h-4 w-4 mr-2" />
            <div className="flex justify-between items-center w-full">
              <span>MaaS API key not configured. Machine operations will be unavailable.</span>
              <Button
                onClick={() => setShowModal('updateApiKey')}
                className="bg-yellow-600 hover:bg-yellow-700 text-white flex items-center ml-4"
              >
                <Key className="h-4 w-4 mr-2" />
                Configure API Key
              </Button>
            </div>
          </Alert>
        )}

        {error && (
          <Alert variant="destructive" className="mb-6 bg-red-900/50 border-red-700">
            <AlertTriangle className="h-4 w-4 mr-2" />
            {error}
          </Alert>
        )}

        {success && (
          <Alert className="mb-6 bg-green-900/50 border-green-700 text-green-100">
            <div className="flex items-center">
              <Check className="h-4 w-4 mr-2" />
              {success}
            </div>
          </Alert>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <Shield className="h-5 w-5 mr-2 text-blue-400" />
                <h2 className="text-xl font-semibold">Users</h2>
              </div>
              <Button
                onClick={() => setShowModal('addUser')}
                className="bg-blue-600 hover:bg-blue-700 text-white flex items-center"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add User
              </Button>
            </div>
            <div className="space-y-4">
              {users.map(user => (
                <div key={user.id} className="bg-gray-700 p-4 rounded-md border border-gray-600">
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="font-medium flex items-center">
                        {user.username}
                        <span className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                          user.role === 'admin' ? 'bg-blue-500' : 'bg-gray-500'
                        }`}>
                          {user.role}
                        </span>
                      </p>
                      <p className="text-sm text-gray-300 mt-1">
                        Pools: {user.pools || 'none'}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedUser(user);
                          setShowModal('changePassword');
                        }}
                        className="bg-gray-600 hover:bg-gray-500"
                      >
                        <Key className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setSelectedUser(user);
                          setShowModal('editUser');
                        }}
                        className="bg-blue-600 hover:bg-blue-500"
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="destructive"
                            size="sm"
                            className="bg-red-600 hover:bg-red-500"
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className="bg-gray-800 border-gray-700 text-white">
                          <AlertDialogHeader>
                            <AlertDialogTitle className="text-white">Delete User</AlertDialogTitle>
                            <AlertDialogDescription className="text-white text-muted-foreground">
                              Are you sure you want to delete {user.username}? This action cannot be undone.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel className="bg-gray-700 hover:bg-gray-600 text-white">
                              Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                              className="bg-red-600 hover:bg-red-500 text-white"
                              onClick={() => handleDeleteUser(user.username)}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>
                  </div>
                </div>
              ))}
              {users.length === 0 && (
                <div className="text-center text-gray-400 py-4">
                  No users found
                </div>
              )}
            </div>
          </div>

          <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <Database className="h-5 w-5 mr-2 text-purple-400" />
                <h2 className="text-xl font-semibold">Resource Pools</h2>
              </div>
              <div className="flex space-x-2">
                <Button
                  onClick={() => setShowModal('updateApiKey')}
                  className="bg-yellow-600 hover:bg-yellow-700 text-white flex items-center"
                >
                  <Key className="h-4 w-4 mr-2" />
                  {isKeyConfigured ? 'Update API Key' : 'Configure API Key'}
                </Button>
                <Button
                  onClick={() => setShowModal('addPool')}
                  className="bg-purple-600 hover:bg-purple-700 text-white flex items-center"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Pool
                </Button>
              </div>
            </div>
            <div className="space-y-4">
              {pools.map(pool => (
                <div key={pool.id} className="bg-gray-700 p-4 rounded-md border border-gray-600">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-medium">{pool.name}</p>
                      {pool.description && (
                        <p className="text-sm text-gray-300">{pool.description}</p>
                      )}
                    </div>
                    {pool.name.toLowerCase() !== 'default' && (
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="destructive"
                            size="sm"
                            className="bg-red-600 hover:bg-red-500"
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className="bg-gray-800 border-gray-700 text-white">
                          <AlertDialogHeader className="flex flex-col space-y-2 text-center sm:text-left">
                            <AlertDialogTitle className="text-lg font-semibold text-white">Delete Pool</AlertDialogTitle>
                            <AlertDialogDescription className="text-sm text-white">
                              Are you sure you want to delete {pool.name}? This action cannot be undone.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
                            <AlertDialogCancel className="bg-gray-700 hover:bg-gray-600 text-white mt-2 sm:mt-0">
                              Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                              className="bg-red-600 hover:bg-red-500 text-white"
                              onClick={() => handleDeletePool(pool.name)}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    )}
                  </div>
                </div>
                              ))}
                              {pools.length === 0 && (
                                <div className="text-center text-gray-400 py-4">
                                  No resource pools found
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                
                        <div className="mt-6">
                          <LogViewer />
                        </div>
                
                        {renderModals()}
                      </div>
                    </div>
                  );
                };
                
                export default AdminPanel;