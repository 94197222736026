// App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PowerControlUI from './PowerControlUI';
import AdminPanel from './AdminPanel';
import { jwtDecode } from 'jwt-decode';

const getUserFromToken = () => {
  const token = localStorage.getItem('authToken');
  if (!token) return null;
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('authToken');
  return token ? children : <Navigate to="/" />;
};

const AdminRoute = ({ children }) => {
  const user = getUserFromToken();
  return user?.role === 'admin' ? children : <Navigate to="/" />;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PowerControlUI />} />
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <AdminPanel />
            </AdminRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
